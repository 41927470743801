//
// Override global variables
//

// Theme colors
$primary:       									#F0461E;
$primary-hover:    									#F27052;
$primary-light:    									#C9F7F5;
$primary-inverse:  									#FFFFFF;

$info:       									    #6993FF;
$info-light:    									#E1E9FF;
$info-hover:    									#4A7DFF;
$info-inverse:  									#FFFFFF;

$success:       									#1bc5bd;
$success-hover:    									#1bc5bd;
$success-light:    									#E1F0FF;
$success-inverse:  									#FFFFFF;
